html,
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    min-width: 320px;
    background: var(--bg-white-blue);
    font-family: Montserrat, Helvetica, Verdana, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
    font-display: optional;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
}

@supports (-webkit-touch-callout: none) {
    html,
    body {
        scroll-behavior: auto;
    }
}

ins.adsbygoogle[data-ad-status='unfilled'] {
    display: none !important;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

button {
    font-family: Montserrat, Helvetica, Verdana, Arial, sans-serif;
}

input {
    font-family: Montserrat, Helvetica, Verdana, Arial, sans-serif;
}

/* for ShareThis */
@media (min-width: 1025px) and (hover: hover) {
    #st-2 {
        flex-direction: column;
    }
}

#st-2 {
    z-index: 100 !important;
}

/* Amazon chat */
.chatWrapper {
    position: fixed;
    height: 100%;
    right: 20px;
    bottom: 0;
    z-index: 2;
}

.customButton {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 100px;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: green;
    color: #fff;
    cursor: pointer;
    text-align: center;
    line-height: 60px;
}

.formWrapper {
    width: 300px;
    height: 475px;
    position: sticky;
    top: calc(100vh - 590px);
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    z-index: 1;
    overflow: hidden;
}

.formHeader {
    padding: 15px 20px;
    background: linear-gradient(90deg, #03d9b1 0%, #1cbbd9 100%);
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}

.formBody {
    padding: 30px 20px;
}

.formControl {
    margin-bottom: 20px;
    position: relative;
}

.formControl .error {
    color: red;
    margin: 0;
    padding: 0;
}

.formLabel {
    position: absolute;
    background: #fff;
    font-size: 11px;
    line-height: 11px;
    top: -6px;
    left: 18px;
    padding: 0 3px;
}

.formWrapper input,
.formWrapper select {
    width: 100%;
    border: 1px solid #c4c4c4;
    border-radius: 50px;
    height: 40px;
    box-sizing: border-box;
    padding: 10px 20px;
    line-height: 20px;
    outline: none;
}

.formWrapper input::placeholder {
    opacity: 0.8;
    transition: all ease 0.4s;
}

.formWrapper input:focus {
    border: 1px solid #1cbbd9;
}

.formWrapper input:focus::placeholder {
    opacity: 0;
    transition: all ease 0.4s;
}

.formButton {
    width: 100%;
    background: linear-gradient(90deg, #03d9b1 0%, #1cbbd9 100%);
    color: #fff;
    border-radius: 50px;
    border: none;
    height: 38px;
    text-align: center;
    cursor: pointer;
    line-height: 36px;
    box-sizing: border-box;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
}

.formButton:disabled {
    cursor: default;
    opacity: 0.7;
}

.loading {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.chatWindowWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    margin: -15px;
}

.chatWindow {
    width: 100%;
    flex: 1;
    overflow-y: auto;
}
.formSendWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
}

.formSendWrapper input {
    width: 100%;
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 36px;
    box-sizing: border-box;
    padding: 7px 10px;
    line-height: 20px;
    outline: none;
}

.formSendWrapper input::placeholder {
    opacity: 0.8;
    transition: all ease 0.4s;
}

.formSendWrapper input:focus::placeholder {
    opacity: 0;
    transition: all ease 0.4s;
}

.chatSendButton {
    border: 1px solid #ccc;
    background: linear-gradient(90deg, #03d9b1 0%, #1cbbd9 100%);
    color: #fff;
    border-radius: 5px;
    border: none;
    height: 36px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    box-sizing: border-box;
    text-transform: uppercase;
    outline: none;
    padding: 0 10px;
    margin-left: 5px;
}

.messageWrapper {
    max-width: 100%;
    width: 100%;
    margin: 5px 0;
}
.messageBlock {
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
    font-size: 14px;
}
.messageBlockTime {
    margin: 0 5px;
    font-size: 12px;
    opacity: 0.7;
}

.custom_btn {
    position: fixed;
    z-index: 2;
    bottom: 90px;
    right: 20px;
    background: linear-gradient(90deg, #03d9b1 0%, #1cbbd9 100%);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: none;
    float: right;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 8px rgb(0 0 0 / 30%);
    outline: none;
    cursor: pointer;
    transition: all 0.5s ease;
}

@media (min-width: 500px) and (max-width: 992px) {
    .custom_btn {
        bottom: 75px;
    }
}

@media (max-width: 500px) {
    .custom_btn {
        bottom: 80px;
    }
}

.custom_btn svg {
    position: absolute;
    top: 22%;
    right: 22%;
    width: 30px;
    height: 30px;
}

.sc-Rmtcm {
    color: red !important;
}

.tippy-box[data-theme~='green'] {
    background-color: #27ae60;
    color: #fff;
    border-radius: 20px;
    padding: 20px;
    font-size: 14px;
}

.tippy-box[data-theme~='green'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #27ae60;
}
.tippy-box[data-theme~='green'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: #27ae60;
}
.tippy-box[data-theme~='green'][data-placement^='left'] > .tippy-arrow::before {
    border-left-color: #27ae60;
}
.tippy-box[data-theme~='green'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: #27ae60;
}

.tippy-box[data-theme~='green'] > .tippy-arrow::before {
    left: -7px !important;
    left: -130px !important;
    transform: scale(2);
}

@media (max-width: 991px) {
    .formWrapper {
        height: 455px;
    }
    .formHeader {
        padding: 15px 20px;
        background: linear-gradient(90deg, #03d9b1 0%, #1cbbd9 100%);
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
    }
}

@keyframes fadeIn {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.3);
    }
}
.StripeElement {
    display: block;
    padding: 14.5px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    border: 1px solid #979797;
    outline: 0;
    border-radius: 4px;
    background: white;
    font-display: optional;
}

.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    border-color: #40a9ff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

.show[name='amazon-connect-chat-widget'] #root {
    border-radius: 15px;
}

#amazon-connect-chat-widget > div {
    bottom: 90px;
}

@media (min-width: 500px) and (max-width: 992px) {
    #amazon-connect-chat-widget > div {
        bottom: 75px;
    }
}

@media (max-width: 500px) {
    #amazon-connect-chat-widget > div {
        bottom: 80px;
    }
}

#amazon-connect-chat-widget > div > div {
    border-radius: 15px;
    margin-bottom: 15px;
}

@media (max-width: 600px) {
    #amazon-connect-chat-widget > div > div {
        border-radius: 0;
        margin-bottom: 0;
    }
}
